import { render, staticRenderFns } from "./TransactionIso.vue?vue&type=template&id=f4d737fc&scoped=true&"
import script from "./TransactionIso.vue?vue&type=script&lang=ts&"
export * from "./TransactionIso.vue?vue&type=script&lang=ts&"
import style0 from "./TransactionIso.vue?vue&type=style&index=0&id=f4d737fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4d737fc",
  null
  
)

export default component.exports